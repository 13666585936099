import React from 'react'
import { MDXTag } from '@mdx-js/tag'




export default ({components, ...props}) => <MDXTag name="wrapper"  components={components}></MDXTag>

export const _frontmatter = {"templateKey":"map-navigation","title":"Previous Explorations","key":"previous-explorations","parentKey":"explore-gabii"};

  